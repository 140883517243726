import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HttpErrorHandler, HandleError } from 'app/main/content/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { SiteConfig } from './_config/site.config';
declare let ClientIP: any;

@Injectable()
export class AppCommonService {
  IPAddress: any;
  SpinnerTimeout = 500;
  // private apiUrl = 'http://192.168.1.87/';
  //private apiUrl = 'http://localhost:64710/api/';
  // private apiUrl = 'http://planworkweb.com/api/';
  /* private apiUrl = 'http://192.168.1.247:2228/api/'; */
  // private apiUrl = 'http://pmsbeta.vertexplus.com/api/';
  //private apiUrl = 'http://pms.vertexplus.com/api/';
  //private apiUrl = 'http://pmsvs.vertexplus.com/api/';
  //private apiUrl = 'http://pmssi.vertexplus.com/api/';
  //private apiUrl = 'http://pms.sprinkdigital.com/api/';
  // private apiUrl = 'http://pmsbeta.vertexplus.com/api/';
  //private apiUrl = 'http://pms.vertexplus.com/api/';
  private baseUrl: any;
  private baseUrlLeave: any;
  private baseInOutLeave: any;
  private handleError: HandleError;
  private token: any;

  rightNow: any;
  jan1: any;
  june1: any;
  temp: any;
  jan2: any;
  june2: any;
  std_time_offset: any;
  daylight_time_offset: any;
  dst: any;
  hemisphere: any;
  minutes: any;
  hours: any;
  mins: any;
  secs: any;
  display_hours: any;
  l: any = {};
  apiUrl: any;
  timeFormate: any =[
    {Time : '10:00 AM', Value : '10:00'},
    {Time : '10:15 AM', Value : '10:15'},
    {Time : '10:30 AM', Value : '10:30'},
    {Time : '10:45 AM', Value : '10:45'},

    {Time : '11:00 AM', Value : '11:00'},
    {Time : '11:15 AM', Value : '11:15'},
    {Time : '11:30 AM', Value : '11:30'},
    {Time : '11:45 AM', Value : '11:45'},

    {Time : '12:00 PM', Value : '12:00'},
    {Time : '12:15 PM', Value : '12:15'},
    {Time : '12:30 PM', Value : '12:30'},
    {Time : '12:45 PM', Value : '12:45'},

    {Time : '01:00 PM', Value : '13:00'},
    {Time : '01:15 PM', Value : '13:15'},
    {Time : '01:30 PM', Value : '13:30'},
    {Time : '01:45 PM', Value : '13:45'},

    {Time : '02:00 PM', Value : '14:00'},
    {Time : '02:15 PM', Value : '14:15'},
    {Time : '02:30 PM', Value : '14:30'},
    {Time : '02:45 PM', Value : '14:45'},

    {Time : '03:00 PM', Value : '15:00'},
    {Time : '03:15 PM', Value : '15:15'},
    {Time : '03:30 PM', Value : '15:30'},
    {Time : '03:45 PM', Value : '15:45'},

    {Time : '04:00 PM', Value : '16:00'},
    {Time : '04:15 PM', Value : '16:15'},
    {Time : '04:30 PM', Value : '16:30'},
    {Time : '04:45 PM', Value : '16:45'},

    {Time : '05:00 PM', Value : '17:00'},
    {Time : '05:15 PM', Value : '17:15'},
    {Time : '05:30 PM', Value : '17:30'},
    {Time : '05:45 PM', Value : '17:45'},

    {Time : '06:00 PM', Value : '18:00'},
    {Time : '06:15 PM', Value : '18:15'},
    {Time : '06:30 PM', Value : '18:30'},
    {Time : '06:45 PM', Value : '18:45'},

    {Time : '07:00 PM', Value : '19:00'},
    {Time : '07:15 PM', Value : '19:15'},
    {Time : '07:30 PM', Value : '19:30'},
    {Time : '07:45 PM', Value : '19:45'},

    {Time : '08:00 PM', Value : '20:00'},
    {Time : '08:15 PM', Value : '20:15'},
    {Time : '08:30 PM', Value : '20:30'},
    {Time : '08:45 PM', Value : '20:45'},

    {Time : '09:00 PM', Value : '21:00'},
    {Time : '09:15 PM', Value : '21:15'},
    {Time : '09:30 PM', Value : '21:30'},
    {Time : '09:45 PM', Value : '21:45'},

    {Time : '10:00 PM', Value : '22:00'},
    {Time : '10:15 PM', Value : '22:15'},
    {Time : '10:30 PM', Value : '22:30'},
    {Time : '10:45 PM', Value : '22:45'},

    {Time : '11:00 PM', Value : '23:00'},
    {Time : '11:15 PM', Value : '23:15'},
    {Time : '11:30 PM', Value : '23:30'},
    {Time : '11:45 PM', Value : '23:45'}, 
    
    {Time : '12:00 AM', Value : '00:00'},
    {Time : '12:15 AM', Value : '00:15'},
    {Time : '12:30 AM', Value : '00:30'},
    {Time : '12:45 AM', Value : '00:45'},

    {Time : '01:00 AM', Value : '01:00'},
    {Time : '01:15 AM', Value : '01:15'},
    {Time : '01:30 AM', Value : '01:30'},
    {Time : '01:45 AM', Value : '01:45'},

    {Time : '02:00 AM', Value : '02:00'},
    {Time : '02:15 AM', Value : '02:15'},
    {Time : '02:30 AM', Value : '02:30'},
    {Time : '02:45 AM', Value : '02:45'},

    {Time : '03:00 AM', Value : '03:00'},
    {Time : '03:15 AM', Value : '03:15'},
    {Time : '03:30 AM', Value : '03:30'},
    {Time : '03:45 AM', Value : '03:45'},

    {Time : '04:00 AM', Value : '04:00'},
    {Time : '04:15 AM', Value : '04:15'},
    {Time : '04:30 AM', Value : '04:30'},
    {Time : '04:45 AM', Value : '04:45'},

    {Time : '05:00 AM', Value : '05:00'},
    {Time : '05:15 AM', Value : '05:15'},
    {Time : '05:30 AM', Value : '05:30'},
    {Time : '05:45 AM', Value : '05:45'},

    {Time : '06:00 AM', Value : '06:00'},
    {Time : '06:15 AM', Value : '06:15'},
    {Time : '06:30 AM', Value : '06:30'},
    {Time : '06:45 AM', Value : '06:45'},

    {Time : '07:00 AM', Value : '07:00'},
    {Time : '07:15 AM', Value : '07:15'},
    {Time : '07:30 AM', Value : '07:30'},
    {Time : '07:45 AM', Value : '07:45'},

    {Time : '08:00 AM', Value : '08:00'},
    {Time : '08:15 AM', Value : '08:15'},
    {Time : '08:30 AM', Value : '08:30'},
    {Time : '08:45 AM', Value : '08:45'},

    {Time : '09:00 AM', Value : '09:00'},
    {Time : '09:15 AM', Value : '09:15'},
    {Time : '09:30 AM', Value : '09:30'},
    {Time : '09:45 AM', Value : '09:45'},
]

  private subject = new Subject<any>();
  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler) {
    this.http.get<any>('https://jsonip.com')
      .subscribe(data => {
        console.log(data)
        const serverIp = data.ip;
        const privateIP = ClientIP;
        console.log(privateIP);
        const IP = serverIp + ':' + privateIP;
        this.IPAddress = IP;
      });
    this.handleError = this.httpErrorHandler.createHandleError();
    this.apiUrl = SiteConfig.baseUrl;
    /* const currentUrl = window.location.href.split('/');
    this.apiUrl = currentUrl[0] + '//' + currentUrl[2] + '/api/'; */
    this.baseUrl = this.apiUrl + 'Services/';
    this.baseUrlLeave = this.apiUrl + 'Leave/';
    this.baseInOutLeave = this.apiUrl + 'InOut/';
  }

  postService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(this.handleError('Error'))
      );
  }

  postLeave(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlLeave + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(this.handleError('Error'))
      );
  }

  InOut(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseInOutLeave + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(this.handleError('Error'))
      );
  }

  login(user: any, url: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + url, user,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(this.handleError('Error'))
      );
  }

  user(): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const userData = {
      UserName: localStorage.getItem('emailId')
    };
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + 'UserList', userData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(this.handleError('Error'))
      );
  }

 /*  coverBase64(): Observable<any> {
    
  } */

  //Current Time 

  calculate_time_zone() {
    this.rightNow = new Date();
    this.jan1 = new Date(this.rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);
    this.june1 = new Date(this.rightNow.getFullYear(), 6, 1, 0, 0, 0, 0);
    this.temp = this.jan1['toGMTString']();
    this.jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(' ') - 1));
    this.temp = this.june1['toGMTString']();
    this.june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(' ') - 1));
    this.std_time_offset = (this.jan1 - this.jan2) / (1000 * 60 * 60);
    this.daylight_time_offset = (this.june1 - this.june2) / (1000 * 60 * 60);
    this.dst;
    if (this.std_time_offset == this.daylight_time_offset) {
      this.dst = '0';
    } else {
      // positive is southern, negative is northern hemisphere
      this.hemisphere = this.std_time_offset - this.daylight_time_offset;
      if (this.hemisphere >= 0)
        this.std_time_offset = this.daylight_time_offset;
      this.dst = '1'; // daylight savings time is observed
    }

    // check just to avoid error messages
    this.minutes = this.convert(this.std_time_offset);
    return this.minutes;
  }

  convert(value) {
    this.hours = parseInt(value);
    value -= parseInt(value);
    value *= 60;
    this.mins = parseInt(value);
    value -= parseInt(value);
    value *= 60;
    this.secs = parseInt(value);
    this.display_hours = this.hours;
    // handle GMT case (00:00)
    if (this.hours == 0) {
      this.display_hours = '00';
    } else if (this.hours > 0) {
      // add a plus sign and perhaps an extra 0
      this.display_hours = (this.hours < 10) ? '+0' + this.hours : '+' + this.hours;
    } else {
      // add an extra 0 if needed 
      this.display_hours = (this.hours > -10) ? '-0' + Math.abs(this.hours) : this.hours;
    }

    this.mins = (this.mins < 10) ? '0' + this.mins : this.mins;
    return this.display_hours + ':' + this.mins;
  }

  sendUserData(a: any) {
    console.log(a)
    this.subject.next({ a: a });
  }
  getUserData(): Observable <any> {
    return this.subject.asObservable();
  }
}
