import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { SiteConfig } from 'app/main/content/apps/_config';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent implements OnInit {
    @Input() layout = 'vertical';
    @Input('navigation') navigation: any;
    user: any;
    URL: any;
    constructor() {
        this.user = JSON.parse(localStorage.getItem('user'));
        
    }
    
    ngOnInit() {
        console.log(SiteConfig.baseUrl)
        this.URL = SiteConfig.baseUrl;
    }
}
