import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModalComponent } from './common-modal.component';
import { MatButtonModule, MatIconModule, MatDialogModule } from '@angular/material';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { MaterialModule } from 'app/main/content/components/angular-material/material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CommonModalComponent
],
imports: [ 
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MaterialModule,
    FormsModule
],
entryComponents: [
  CommonModalComponent
],
providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }]
})
export class CommonModalModule { }
