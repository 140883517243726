const currentUrl = window.location.href.split('/');
const apiUrl = currentUrl[0] + '//' + currentUrl[2] + '/api/';

export const SiteConfig = { 
  //  baseUrl: apiUrl, 
  //  siteUrl: apiUrl + 'Services/',
  
  //  baseUrl: 'http://pms.vertexplus.com/api/',
  //  siteUrl: 'http://pms.vertexplus.com/api/Services/' 
  
  // baseUrl: 'http://pms02.vertexplus.com/api/',
  // siteUrl: 'http://pms02.vertexplus.com/api/Services/' 
  
//   baseUrl: 'http://pms03.vertexplus.com/api/',
//   siteUrl: 'http://pms03.vertexplus.com/api/Services/' 
  
  // baseUrl: 'http://pms05.vertexplus.com/api/',
  // siteUrl: 'http://pms05.vertexplus.com/api/Services/'
  
  // baseUrl: 'http://pms06.sprinkdigital.com/api/',
  // siteUrl: 'http://pms06.sprinkdigital.com/api/Services/'

  // baseUrl: 'http://pmssi.vertexplus.com/api/',
  // siteUrl: 'http://pmssi.vertexplus.com/api/Services/'

  baseUrl: 'https://pmsbeta.vertexplus.com/api/',
  siteUrl: 'https://pmsbeta.vertexplus.com/api/Services/'
};
