import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';

import { fuseConfig } from './fuse-config';
import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { AppStoreModule } from './store/store.module';
import { CommonModalModule } from '@fuse/components/common-modal/common-modal.module';

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/content/apps/apps.module').then(m => m.FuseAppsModule)
    },  
    {
        path: '**',
        redirectTo: 'apps/dashboards'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        
        TranslateModule.forRoot(),

        FuseModule.forRoot(fuseConfig),       

        AppStoreModule,
        FuseMainModule,
        CommonModalModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
