import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppCommonService } from '../content/apps/app.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  modalTitle: any;
  action: any;
  ChangePass: any = {};
  user: any;
  Warning: any;
  IsWarning = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  @ViewChild('f', { static: false }) form: any;
  constructor(
    public dialogRef: MatDialogRef<ForgetPasswordComponent>,
    private spinner: NgxSpinnerService,
    private loginService: AppCommonService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private router: Router, 
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.action = data.action;
    this.modalTitle = data.modalTitle;
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log(this.user)
  }

  ngOnInit() {
  }
  Changed() {
    console.log(this.ChangePass)
    if (this.form.valid && (this.ChangePass.EmployeeNewPassword === this.ChangePass.EmployeeConfirmPassword) && (this.ChangePass.EmployeeNewPassword != this.ChangePass.EmployeeOldPassword)) {
      this.spinner.show();
      this.loginService.postService(this.ChangePass, 'ChangePassword')
        .subscribe(response => {
          console.log(response)
          if (response.body.Success) {
              this.dialogRef.close();
            this.router.navigate(['apps/login']);
            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
              disableClose: false,
              data: { action: 'success' }
            });
            this.spinner.hide();
            localStorage.clear();
            this.cookieService.delete('emailId');
            this.confirmDialogRef.componentInstance.confirmMessage = response.body.Result.TransactionMessage.Message;
          } else {
            this.spinner.hide();
            // this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            //   disableClose: false,
            //   data: { action: 'alert' }
            // });
            // this.confirmDialogRef.componentInstance.confirmMessage = response.body.Result.TransactionMessage.Message;
            this.IsWarning = true;
            this.Warning = response.body.Result.TransactionMessage.Message;
          }
        });
    }
  }
}
