import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatRadioModule,
    MatStepperModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatListModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTreeModule,
    MatButtonToggleModule
} from '@angular/material';
import { DataTablesModule } from 'angular-datatables';
import { DatepipeModule } from './pipes/date-pipes/datepipe.module';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatRadioModule,
        MatStepperModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatListModule,
        MatCheckboxModule,
        MatDialogModule,
        MatProgressBarModule, MatRadioModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        DataTablesModule,
        MatMenuModule,
        DatepipeModule,
        CdkStepperModule,
        MatTreeModule,
        MatButtonToggleModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatRadioModule,
        MatStepperModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatListModule,
        MatCheckboxModule,
        MatDialogModule,
        MatProgressBarModule, MatRadioModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        DataTablesModule,
        MatMenuModule,
        DatepipeModule,
        CdkStepperModule,
        MatTreeModule,
        MatButtonToggleModule
    ]
})
export class FuseSharedModule {
}
