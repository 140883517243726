import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseContentComponent } from 'app/main/content/content.component';
import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
    declarations: [
        FuseContentComponent,

    ],
    imports: [
        RouterModule,
        NgxSpinnerModule,
        FuseSharedModule,
    ],
    exports: [
        FuseContentComponent
    ]
})
export class FuseContentModule {
}
