import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;
/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private messageService: MessageService, public dialog: MatDialog, private spinner: NgxSpinnerService,
  private router: Router) { }
  createHandleError = (serviceName = '') => <T>
    (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result)
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.spinner.hide();
      console.log(error)
      this.dialog.closeAll();
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false,
        data: { action: 'bad_request', statusText: error.error }
      });
      this.router.navigate(['apps/login']);
      const message = (error.error instanceof ErrorEvent) ?
        error.error.message :
        `server returned code ${error.status} with body "${error.error}"`;
      this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);
      return of(result);
    };
  }
}