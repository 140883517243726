import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import {
    navigation
} from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { FuseToolbarComponent } from '../toolbar/toolbar.component';
import { AppCommonService } from '../content/apps/app.service';
import { NavbarService } from './navbar.service';
import { SubSink } from '../../sub-sink';
@Component({
    selector: 'fuse-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavbarComponent implements OnInit, OnDestroy {
    @ViewChild(FuseToolbarComponent, { static: false }) child;
    private fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private subs = new SubSink();
    menu: any;
    user: any[] = [];
    navList: any;
    allNavigation: any;
    activeClass: any;
    @ViewChild(FusePerfectScrollbarDirective, { static: false }) set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this.fusePerfectScrollbar = theDirective;

        this.navigationServiceWatcher =
        this.subs.sink =  this.navigationService.onItemCollapseToggled.subscribe(() => {
                this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
                    this.fusePerfectScrollbar.update();
                }, 310);
            });
    }

    @Input() layout;
    navigation: any;
    companyNavigation: any;
    configurationNavigation: any;
    sideMenuOptions: any;
    navigationServiceWatcher: Subscription;
    fusePerfectScrollbarUpdateTimeout;

    constructor(
        private sidebarService: FuseSidebarService,
        private navigationService: FuseNavigationService,
        private router: Router,
        //private toolbarService: ToolbarService,
        private appService: AppCommonService,
        private navbarService: NavbarService
    ) {

        // Navigation data
        //    this.navigation = navigation;

        // Admin Navigation
        // this.companyNavigation = companyNavigation;
        // this.configurationNavigation = configurationNavigation;
        // Default layout

        this.layout = 'vertical';
        if (localStorage.getItem('token')) {
            this.subs.sink =   this.appService.postService(null, 'MenuSectionGetById')
                .subscribe(response => {
                    const user = JSON.parse(localStorage.getItem('user'));
                    this.allNavigation = response.body.Result.navigation;
                    /* const menuData = [];
                    this.allNavigation.forEach(element => {
                        if (element.RoleId.indexOf(user.UserRole.UserRoleId) > -1) {
                            if (element.Pages.length > 0) {
                                element.Pages.forEach(pages => {
                                    menuData.push({ id: pages.id, url: pages.url });
                                });
                            }
                            
                        }                            
                    });
                    localStorage.setItem('menu', JSON.stringify(menuData)) */
                    const menu = localStorage.getItem('isOpen');
                    this.activeClass = menu;
                    if (menu) {
                        this.navigation = this.allNavigation.filter(x => x.Mid === menu);
                    }
                });
        }
    }

    ngOnInit(): void {
        this.subs.sink =   this.navbarService.change.subscribe(isOpen => {
            if (isOpen && this.allNavigation) {
                this.navigation = this.allNavigation.filter(x => x.Mid === isOpen);
            }
        });
        //this.toolbarService.currentMenu.subscribe(menu => this.menu = menu);
        this.subs.sink =   this.router.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd) {
                    if (this.sidebarService.getSidebar('navbar')) {
                        this.sidebarService.getSidebar('navbar').close();
                    }
                }
            }
        );

    }

    ngOnDestroy() {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }

        if (this.navigationServiceWatcher) {
            this.navigationServiceWatcher.unsubscribe();
        }
    }

    toggleSidebarOpened() {
        this.sidebarService.getSidebar('navbar').toggleOpen();
    }

    toggleSidebarFolded() {
        this.sidebarService.getSidebar('navbar').toggleFold();
    }
}
