export const navigation = [
    {
        'id': 'my-team',
        'title': 'My Team',
        'type': 'collapse',
        'Pages': [
            {
                'id': 'my-team',
                'title': 'My Team',
                'type': 'item',
                'url': 'apps/dashboard/my-team'
            },
            {
                'id': 'assignments-project',
                'title': 'Assignments Project',
                'type': 'item',
                'url': 'apps/dashboard/assignments-project'
            },
            {
                'id': 'detach',
                'title': 'Detach',
                'type': 'item',
                'url': 'apps/dashboard/detach'
            }
        ]
    }
]

export const navigation1111 = [
    {
        'id': 'dashboard',
        'title': 'Dashboard',
        'type': 'collapse',
        'children': [
            {
                'id': 'My-Requisitions',
                'title': 'My Requisitions',
                'type': 'item',
                'url': 'apps/dashboards/dashboard'
            },
            {
                'id': 'My_Reimbursements',
                'title': 'My Reimbursements',
                'type': 'item',
                'url': '#'
            },
            {
                'id': 'My_Alerts',
                'title': 'My Alerts',
                'type': 'item',
                'url': '#'
            },
        ]
    },
    {
        'id': 'my-team',
        'title': 'My Team',
        'type': 'collapse',
        'children': [
            {
                'id': 'my-team',
                'title': 'My Team',
                'type': 'item',
                'url': 'apps/dashboard/my-team'
            },
            {
                'id': 'assignments-project',
                'title': 'Assignments Project',
                'type': 'item',
                'url': 'apps/dashboard/assignments-project'
            },
            {
                'id': 'detach',
                'title': 'Detach',
                'type': 'item',
                'url': 'apps/dashboard/detach'
            }
        ]
    },
    {
        'id': 'configure',
        'title': 'Configure',
        'type': 'collapse',
        // 'url': 'apps/admin/company/details',
        'children': [
            {
                'id': 'configure-client',
                'title': 'Client',
                'type': 'collapse',

                'children': [
                    {
                        'id': 'industry-type',
                        'title': 'Industry Types',
                        'type': 'item',
                        'url': 'apps/admin/master/industry-type'
                    },
                    {
                        'id': 'cstmr-segment',
                        'title': 'Customer Segment',
                        'type': 'item',
                        'url': 'apps/admin/master/customer-segment'
                    },

                    {
                        'id': 'refrnce-mode',
                        'title': 'Reference Mode',
                        'type': 'item',
                        'url': 'apps/admin/master/reference-mode'
                    },
                    {
                        'id': 'key-feature',
                        'title': 'Key Features',
                        'type': 'item',
                        'url': 'apps/admin/master/key-feature'
                    },
                    {
                        'id': 'organisation-type',
                        'title': 'Organisation Type',
                        'type': 'item',
                        'url': 'apps/admin/master/organisation-type'
                    }
                ]
            },
            {
                'id': 'configure-commons',
                'title': 'Commons',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'lead-type',
                        'title': 'Lead Type',
                        'type': 'item',
                        'url': 'apps/admin/master/lead-type'
                    },
                    {
                        'id': 'activities',
                        'title': 'Activity',
                        'type': 'item',
                        'url': 'apps/admin/master/activities'
                    },
                    {
                        'id': 'sub-activity',
                        'title': 'Sub Activity',
                        'type': 'item',
                        'url': 'apps/admin/master/sub-activity'
                    },
                    {
                        'id': 'unit',
                        'title': 'Units',
                        'type': 'item',
                        'url': 'apps/admin/master/units'
                    }
                ]
            },
            {
                'id': 'Manager_Administration',
                'title': 'Leads',
                'translate': 'NAV.ADMIN_CONFIGURATIONS',
                'type': 'collapse',
                'children': [
                    /* {
                        'id': 'pro-type',
                        'title': 'Project Type',
                        'type': 'item',
                        'url': 'apps/admin/master/project-type'
                    }, */
                    {
                        'id': 'mode-po',
                        'title': 'Mode of PO',
                        'type': 'item',
                        'url': 'apps/admin/master/mode-of-po'
                    }
                ]
            },
            {
                'id': 'banks',
                'title': 'Company',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'company-code',
                        'title': 'Company-Code',
                        'type': 'item',
                        'url': 'apps/admin/master/company-code'
                    },
                    {
                        'id': 'currency',
                        'title': 'Currency',
                        'type': 'item',
                        'url': 'apps/admin/master/currency'
                    },
                    {
                        'id': 'banks_name',
                        'title': 'Banks',
                        'type': 'item',
                        'url': 'apps/admin/master/banks'
                    },
                    {
                        'id': 'bank_account',
                        'title': 'Bank Account Types',
                        'type': 'item',
                        'url': 'apps/admin/master/bank-account-types'
                    },
                    {
                        'id': 'department',
                        'title': 'Department',
                        'type': 'item',
                        'url': 'apps/admin/master/department'
                    },
                    {
                        'id': 'document-type',
                        'title': 'Document Type',
                        'type': 'item',
                        'url': 'apps/admin/master/document-type'
                    },
                    {
                        'id': 'company-type',
                        'title': 'Company Type',
                        'type': 'item',
                        'url': 'apps/admin/master/company-type'
                    },
                    {
                        'id': 'nature-business',
                        'title': 'Nature of Business',
                        'type': 'item',
                        'url': 'apps/admin/master/nature-of-business'
                    },
                    {
                        'id': 'holiday-type',
                        'title': 'Holiday Type',
                        'type': 'item',
                        'url': 'apps/admin/master/holiday-type'
                    },
                    {
                        'id': 'payment-term',
                        'title': 'Payment Terms',
                        'type': 'item',
                        'url': '/apps/admin/master/payment-term'
                    },
                    {
                        'id': 'financial-year',
                        'title': 'Financial Year',
                        'type': 'item',
                        'url': '/apps/admin/company/financial-year'
                    },
                    {
                        'id': 'taxtype',
                        'title': 'Tax Type',
                        'type': 'item',
                        'url': '/apps/admin/company/taxtype'
                    },
                    {
                        'id': 'shift',
                        'title': 'Shift',
                        'type': 'item',
                        'url': 'apps/admin/master/shift'
                    },
                    {
                        'id': 'payment',
                        'title': 'Payment Type',
                        'type': 'item',
                        'url': 'apps/admin/master/payment'
                    },
                    {
                        'id': 'invoice',
                        'title': 'Invoice Type',
                        'type': 'item',
                        'url': 'apps/admin/master/invoice'
                    },
                    {
                        'id': 'halt',
                        'title': 'Halt',
                        'type': 'item',
                        'url': 'apps/admin/master/halt'
                    },
                    {
                        'id': 'POtype',
                        'title': 'PO-Type',
                        'type': 'item',
                        'url': 'apps/admin/master/POtype'
                    },
                    {
                        'id': 'estimation',
                        'title': 'Estimation-Buffer',
                        'type': 'item',
                        'url': 'apps/admin/master/estimation'
                    },
                    {
                        'id': 'statutory',
                        'title': 'Statutory-Compliance',
                        'type': 'item',
                        'url': 'apps/admin/master/statutory'
                    },
                    {
                        'id': 'bufferpercentage',
                        'title': 'Buffer-Percentage',
                        'type': 'item',
                        'url': 'apps/admin/master/bufferpercentage'
                    },
                    {
                        'id': 'AddressType',
                        'title': 'Address Type',
                        'type': 'item',
                        'url': 'apps/admin/master/address-type'
                    },
                    {
                        'id': 'General-master',
                        'title': 'General Term',
                        'type': 'item',
                        'url': 'apps/admin/master/general-term'
                    },
                    {
                        'id': 'cost_center_name',
                        'title': 'Cost Center Name',
                        'type': 'item',
                        'url': 'apps/admin/master/cost-center-name'
                    },
                    {
                        'id': 'cost_center_type',
                        'title': 'Cost Center Type',
                        'type': 'item',
                        'url': 'apps/admin/master/cost-center-type'
                    },
                    {
                        'id': 'profit_center',
                        'title': 'Profit Center Name',
                        'type': 'item',
                        'url': 'apps/admin/master/profit-center'
                    },
                    {
                        'id': 'payment-mode',
                        'title': 'Payment Mode',
                        'type': 'item',
                        'url': '/apps/admin/master/payment-mode'
                    },
                    {
                        'id': 'card-type',
                        'title': 'Card Type',
                        'type': 'item',
                        'url': '/apps/admin/master/card-type'
                    },
                ]
            },
            {
                'id': 'user_role_menu',
                'title': 'Users',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'user_role',
                        'title': 'User Role',
                        'type': 'item',
                        'url': 'apps/admin/master/user-role'
                    },
                    {
                        'id': 'user_role_permission',
                        'title': 'Role Permission',
                        'type': 'item',
                        'url': 'apps/admin/master/user-role-permission'
                    }
                ]
            },
            {
                'id': 'employee',
                'title': 'Employee',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'cost_center_name',
                        'title': 'Nationality',
                        'type': 'item',
                        'url': 'apps/admin/master/nationality'
                    },
                    {
                        'id': 'designation',
                        'title': 'Designation',
                        'type': 'item',
                        'url': 'apps/admin/master/designation'
                    },
                    {
                        'id': 'key-technology',
                        'title': 'Key Technology',
                        'type': 'item',
                        'url': 'apps/admin/master/key-technology'
                    },
                    {
                        'id': 'skill-set',
                        'title': 'Skill Set',
                        'type': 'item',
                        'url': 'apps/admin/master/skill-set'
                    },
                    {
                        'id': 'level',
                        'title': 'Level',
                        'type': 'item',
                        'url': 'apps/admin/master/level'
                    },
                    {
                        'id': 'cadre',
                        'title': 'Cadre',
                        'type': 'item',
                        'url': 'apps/admin/master/cadre'
                    }
                ]
            },
            {
                'id': 'misc-menu',
                'title': 'Miscellaneous',
                'type': 'collapse',
                'children': [
                    /*  {
                         'id': 'm-country',
                         'title': 'Country',
                         'type': 'item',
                         'url': 'apps/admin/master/country'
                     },
                     {
                         'id': 'm-state',
                         'title': 'State',
                         'type': 'item',
                         'url': 'apps/admin/master/state'
                     },
                     {
                         'id': 'm-city',
                         'title': 'City',
                         'type': 'item',
                         'url': 'apps/admin/master/city'
                     }, */
                    {
                        'id': 'label',
                        'title': 'Label',
                        'type': 'item',
                        'url': 'apps/admin/master/label'
                    },
                    {
                        'id': 'reason',
                        'title': 'Reason',
                        'type': 'item',
                        'url': 'apps/admin/master/reason'
                    },
                    {
                        'id': 'PreRequisite',
                        'title': 'PreRequisite',
                        'type': 'item',
                        'url': 'apps/admin/master/prerequisite'
                    },
                    {
                        'id': 'sheduletype',
                        'title': 'Schedule Type',
                        'type': 'item',
                        'url': 'apps/admin/master/schedule-type'
                    }
                ]
            },
            {
                'id': 'quality-audit',
                'title': 'Quality Audit',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'testing',
                        'title': 'Testing Type',
                        'type': 'item',
                        'url': 'apps/admin/master/testing'
                    },
                    {
                        'id': 'issuetype',
                        'title': 'Issue Type',
                        'type': 'item',
                        'url': 'apps/admin/master/issue-type'
                    },
                    {
                        'id': 'severity',
                        'title': 'Severity',
                        'type': 'item',
                        'url': 'apps/admin/master/severity'
                    },
                    {
                        'id': 'reproducibility',
                        'title': 'Reproducibility',
                        'type': 'item',
                        'url': 'apps/admin/master/reproducibility'
                    },
                    {
                        'id': 'build',
                        'title': 'Build',
                        'type': 'item',
                        'url': 'apps/admin/master/build'
                    }
                ]
            }
        ]
    },
    {
        'id': 'company',
        'title': 'Company',
        'type': 'collapse',
        //'url': 'apps/admin/master/industry-type',
        'children': [
            {
                'id': 'set-up-company',
                'title': 'Set Up Company',
                'type': 'item',
                'url': '/apps/admin/company/set-up-company'
            },
            {
                'id': 'employees',
                'title': 'Employees',
                'type': 'item',
                'url': '/apps/admin/company/employee'
            },
            {
                'id': 'cost-center',
                'title': 'Cost Centers',
                'type': 'item',
                'url': '/apps/admin/company/cost-center'
            },
            {
                'id': 'profit-center',
                'title': 'Profit Centers',
                'type': 'item',
                'url': '/apps/admin/company/profit-center'
            },
            {
                'id': 'holidays',
                'title': 'Holidays',
                'type': 'item',
                'url': '/apps/admin/company/holidays'
            },
            {
                'id': 'working-schedule',
                'title': 'Working Schedule',
                'type': 'item',
                'url': '/apps/admin/company/working-schedule'
            },

        ]
    },
    {
        'id': 'pre_sales',
        'title': 'Pre Sales',
        'type': 'collapse',
        'children': [
            {
                'id': 'all',
                'title': 'All',
                'type': 'item',
                'url': '/apps/scrumboard/boards'
            },
            {
                'id': 'tobe-started',
                'title': 'To Be Started',
                'type': 'item',
                'url': '#'
            },
            {
                'id': 'in-progress',
                'title': 'In Progress',
                'type': 'item',
                'url': '#'
            },
            {
                'id': 'completed',
                'title': 'Completed',
                'type': 'item',
                'url': '#'
            },
            {
                'id': 'onhold',
                'title': 'On Hold',
                'type': 'item',
                'url': '#'
            },
            {
                'id': 'terminated',
                'title': 'Terminated',
                'type': 'item',
                'url': '#'
            }
        ]
    },
    {
        'id': 'scheduling',
        'title': 'Scheduling',
        'type': 'collapse',
        'children': [
            {
                'id': 'meeting',
                'title': 'Meeting',
                'type': 'item',
                'url': 'apps/scheduling/scheduling'
            },
            {
                'id': 'prospect',
                'title': 'Prospect',
                'type': 'item',
                'url': 'apps/scheduling/scheduling'
            }
        ]
    },
    {
        'id': 'lead',
        'title': 'Leads',
        'type': 'collapse',
        'children': [
            {
                'id': 'current',
                'title': 'Current',
                'type': 'item',
                'url': 'apps/lead/list'
            },
            {
                'id': 'converted',
                'title': 'Converted',
                'type': 'item',
                'url': 'apps/lead/converted-lead'
            },
            {
                'id': 'declined',
                'title': 'Declined',
                'type': 'item',
                'url': 'apps/lead/declined'
            }
        ]
        
    },
    {
        'id': 'clients',
        'title': 'Clients',
        'type': 'collapse',
        'children': [
            /* {
                'id': 'Client',
                'title': 'Client',
                'type': 'item',
                'url': 'apps/clients/client',
            }, */
            {
                'id': 'propective',
                'title': 'Prospectives',
                'type': 'item',
                'url': 'apps/clients/list',
            },
            {
                'id': 'current',
                'title': 'Current',
                'type': 'item',
                'url': 'apps/clients/current',
            },
        ]
    },
    {
        'id': 'project',
        'title': 'Projects',
        'type': 'collapse',
        'children': [
            {
                'id': 'all',
                'title': 'All',
                'type': 'item',
                'url': 'apps/projects/list'
            },
            {
                'id': 'tobe-started',
                'title': 'To Be Started',
                'type': 'item',
                'url': 'apps/projects/tobe-started'
            },
            {
                'id': 'in-progress',
                'title': 'In Progress',
                'type': 'item',
                'url': 'apps/projects/in-progress'
            },
            {
                'id': 'completed',
                'title': 'Completed',
                'type': 'item',
                'url': 'apps/projects/completed'
            },
            {
                'id': 'onhold',
                'title': 'On Hold',
                'type': 'item',
                'url': 'apps/projects/on-hold'
            },
            {
                'id': 'terminated',
                'title': 'Terminated',
                'type': 'item',
                'url': 'apps/projects/terminated'
            }
        ]
    },
    {
        'id': 'services',
        'title': 'Services',
        'type': 'collapse',
        'children': [
            {
                'id': 'all',
                'title': 'All',
                'type': 'item',
                'url': 'apps/service/list'
            },
            {
                'id': 'tobe-started',
                'title': 'To Be Started',
                'type': 'item',
                'url': 'apps/service/to-be-started'
            },
            {
                'id': 'in-progress',
                'title': 'In Progress',
                'type': 'item',
                'url': 'apps/service/in-progress'
            },
            {
                'id': 'completed',
                'title': 'Completed',
                'type': 'item',
                'url': 'apps/service/completed'
            },
            {
                'id': 'onhold',
                'title': 'On Hold',
                'type': 'item',
                'url': 'apps/service/on-hold'
            },
            {
                'id': 'terminated',
                'title': 'Terminated',
                'type': 'item',
                'url': 'apps/service/terminated'
            },
            {
                'id': 'deliverable',
                'title': 'Service Deliverable',
                'type': 'item',
                'url': 'apps/service/service-deliverable'

            }
        ]
    },

    {
        'id': 'Assignments',
        'title': 'Assignments',
        'type': 'collapse',
        'children': [
            {
                'id': 'tasks',
                'title': 'Tasks',
                'type': 'item',
                'url': '/apps/myassignment/myassignment',
            },
            {
                'id': 'reviews',
                'title': 'Reviews',
                'type': 'item',
                'url': '/apps/myassignment/myassignment',
            },
            {
                'id': 'alerts',
                'title': 'Alerts',
                'type': 'item',
                'url': '/apps/myassignment/myassignment',
            }
        ]
    },
    {
        'id': 'qa',
        'title': 'Quality Audit',
        'type': 'collapse',
        'children': [
            {
                'id': 'projects',
                'title': 'Projects',
                'type': 'item',
                'url': '/apps/qa/projects'
            },
            {
                'id': 'services',
                'title': 'Services',
                'type': 'item',
                'url': '/apps/qa/services'
            }
        ]
    },
    {
        'id': 'billing',
        'title': 'Billing',
        'type': 'collapse',
        'children': [
            {
                'id': 'audit',
                'title': 'Audit',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'projects',
                        'title': 'Projects',
                        'type': 'item',
                        'url': 'apps/account/audit-project'
                    },
                    {
                        'id': 'services',
                        'title': 'Services',
                        'type': 'item',
                        'url': 'apps/account/service-audit'
                    }
                ]
            },
            {
                'id': 'account-dueinvoice',
                'title': 'Account Due Invoice',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'account_project',
                        'title': 'Project',
                        'type': 'item',
                        'url': 'apps/account/dueinvoice'
                    },
                    {
                        'id': 'account_accountdueinvoice',
                        'title': 'Service',
                        'type': 'item',
                        'url': 'apps/account/service-accountdueinvoice'
                    },
                ]
            },
            {
                'id': 'Invoices',
                'title': 'Invoices',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'Projects',
                        'title': 'Projects',
                        'type': 'item',
                        'url': 'apps/account/project-invoices'
                    },
                    {
                        'id': 'Services',
                        'title': 'Services',
                        'type': 'item',
                        'url': 'apps/account/service-invoices'
                    },
                ]
            },
            {
                'id': 'Payments',
                'title': 'Payments',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'Pending',
                        'title': 'Pending',
                        'type': 'item',
                        'url': 'apps/account/payment-pending'
                    },
                    {
                        'id': 'Received',
                        'title': 'Received',
                        'type': 'item',
                        'url': 'apps/account/payment-received'
                    }
                ]
            },
            {
                'id': 'Status',
                'title': 'Status',
                'type': 'collapse',
                'children': [
                    {
                        'id': 'Project',
                        'title': 'Project',
                        'type': 'item',
                        'url': 'apps/account/project-status'
                    },
                    {
                        'id': 'Service',
                        'title': 'Service',
                        'type': 'item',
                        'url': 'apps/account/service-status'
                    }
                ]
            }
        ]
    },
    {
        'id': 'cost-center',
        'title': 'Super Admin',
        'type': 'collapse',
        //'url': 'apps/lead/lead',
        'children': [
            {
                'id': 'Language',
                'title': 'Language',
                'type': 'item',
                'url': 'apps/admin/master/language'
            },
            {
                'id': 'location',
                'title': 'Location',
                'type': 'item',
                'url': 'apps/admin/master/location'
            },
            {
                'id': 'Database',
                'title': 'Database',
                'type': 'item',
                'url': 'apps/admin/master/database-master'
            },
            {
                'id': 'Server',
                'title': 'Server',
                'type': 'item',
                'url': 'apps/admin/master/server-master'
            },
            {
                'id': 'Business_Process',
                'title': 'Business Process',
                'type': 'item',
                'url': 'apps/admin/master/business-process'
            },
            {
                'id': 'Status',
                'title': 'Status',
                'type': 'item',
                'url': 'apps/admin/master/status'
            },
            {
                'id': 'Category',
                'title': 'Category',
                'type': 'item',
                'url': 'apps/admin/master/category'
            },
            {
                'id': 'Duration_Unit',
                'title': 'Duration Unit',
                'type': 'item',
                'url': 'apps/admin/master/duration'
            },
            {
                'id': 'Package',
                'title': 'Package',
                'type': 'item',
                'url': 'apps/admin/master/package'
            },
            {
                'id': 'Company_List',
                'title': 'Company List',
                'type': 'item',
                'url': 'apps/admin/company/comapny-list'
            },
            {
                'id': 'Licence',
                'title': 'Licence',
                'type': 'item',
                'url': 'apps/admin/master/Licence'
            },
            {
                'id': 'User_Role',
                'title': 'User Role',
                'type': 'item',
                'url': 'apps/admin/master/user-role'
            }
        ]
    },
    {
        'id': 'reports',
        'title': 'Reports',
        'type': 'collapse',
        'children': [
            {
                'id': 'resource-occupancy',
                'title': 'Resource Occupancy',
                'type': 'item',
                'url': 'apps/resource-occupancy/weekly'
            }
        ]
    }
]

// { path: 'management-projects', component: ManagementProjectsComponent },
// { path: 'management-Prospects', component: ManagementProspectsComponent },
// { path: 'management-Employees', component: ManagementEmployeesComponent },
// { path: 'management-Clients', component: ManagementClientsComponent },