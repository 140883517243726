import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AppCommonService } from 'app/main/content/apps/app.service';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  closeHide = false;
  @ViewChild('f', { static: false }) form: any;
  modelData: any = {};
  action: any;
  modalData: any = {};
  ReworkData: any = {};
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(
    public dialog: MatDialog,
    private appServece: AppCommonService,
    public dialogRef: MatDialogRef<CommonModalComponent>,

    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.modelData = data;
    this.action = data.action;
  }

  ngOnInit(): void {
    $(function() {
      $('body').on('keydown', '#test', function(e) {
        if (e.which === 32 &&  e.target.selectionStart === 0) {
          return false;
        }  
      });
    });
  }
  SaveReason(){
    this.dialogRef.close(this.ReworkData);
  }
}
