import { Component, HostBinding, Input } from '@angular/core';
import { AppCommonService } from 'app/main/content/apps/app.service';
import { InvoiceService } from 'app/main/content/apps/invoice/invoice.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './nav-vertical-item.component.html',
    styleUrls: ['./nav-vertical-item.component.scss']
})
export class FuseNavVerticalItemComponent {
    @HostBinding('class') classes = 'nav-item';
    @Input() item: any;
    userRole: any = {
        Buttons: [],
    };
    countList: any[] = [];
    pageCount: any;
    count: any;
    subscription: Subscription;
    alreadyrun: boolean;
    constructor(
        private appServece: AppCommonService
    ) {
        this.subscription =  this.appServece.getUserData().subscribe(message => {
            console.log(message)
            if (message) {    
              }
      });
    }
    ngOnInit() {
        
        if (this.item.id && !this.alreadyrun) {
            this.getCount();
        }
    }
    getCount() {
        this.appServece.postService(null, 'CountMenuPageSection')
            .subscribe(response => {
          
                this.alreadyrun= true;
                this.countList = response.body.Result;
   
                this.pageCount = this.countList.filter(x => x.PageId == this.item.id)[0];
                console.log(this.pageCount)
                if (this.pageCount != undefined) {
                    this.count = this.pageCount.Count;
                }
            })
    }
}
//for feature use chait
    // @Input('item') item : any;


    // ngOnInit() {
    //     console.log(this.item)
    // }
