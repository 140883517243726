import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent implements AfterViewInit {
    public confirmMessage: string;
    public confirmMessagePara: string;
    currencySymbol: any;
    Amount: any;
    dialogAction: any;
    headingMessage: any;
    statusText: any;
    canShow: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) {          
        this.dialogAction = data.action;
        if (this.dialogAction === 'delete') {
            this.confirmMessage = `Are you sure you want to delete <br> this record?`;
            this.confirmMessagePara = `You will not be able to recover this record`;
        }
        if (this.dialogAction === 'alert' || this.dialogAction === 'bad_request' || this.dialogAction === 'success') {
            this.confirmMessage = data.exceptionCode;
            this.statusText = data.statusText ;
            this.canShow = true;
        }
        if(this.dialogAction === 'warning'){
            this.Amount = data.AvailableBalance;
            this.currencySymbol = data.currency;
        }
        if(this.dialogAction === 'UNDO'){
            this.confirmMessage = `Are you sure you want to UNDO?`
        }
        if(this.dialogAction === 'AlertProceed'){
            this.confirmMessage = `Are you sure you want to Approve <br> And Assign Additional Time Request?`
        }

    }

    ngAfterViewInit(): void {
        this.canShow = true;
        const body = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];
        body.classList.add('common_dialog');
    }

}
