import { Injectable } from '@angular/core';

@Injectable()
export class ServerDateTimeService {
    rightNow: any;
    jan1: any;
    june1: any;
    temp: any;
    jan2: any;
    june2: any;
    stdTimeOffset: any;
    daylightTimeOffset: any;
    hemisphere: any;
    minutes: any;
    hours: any;
    mins: any;
    secs: any;
    displayHours: any;
    constructor() { }

    serverDateTime = () => {
        this.rightNow = new Date();
        this.jan1 = new Date(this.rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);
        this.june1 = new Date(this.rightNow.getFullYear(), 6, 1, 0, 0, 0, 0);
        this.temp = this.jan1['toGMTString']();
        this.jan2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(' ') - 1));
        this.temp = this.june1['toGMTString']();
        this.june2 = new Date(this.temp.substring(0, this.temp.lastIndexOf(' ') - 1));
        this.stdTimeOffset = (this.jan1 - this.jan2) / (1000 * 60 * 60);
        this.daylightTimeOffset = (this.june1 - this.june2) / (1000 * 60 * 60);
        let dst: any;
        if (this.stdTimeOffset === this.daylightTimeOffset) {
            dst = '0';
        } else {
            this.hemisphere = this.stdTimeOffset - this.daylightTimeOffset;
            if (this.hemisphere >= 0) {
                this.stdTimeOffset = this.daylightTimeOffset;
            } else {
                dst = '1';
            }


        }
        this.minutes = this.convert(this.stdTimeOffset);
        return this.minutes;
    }

    convert = (value) => {
        this.hours = parseInt(value, 10);
        value -= parseInt(value, 10);
        value *= 60;
        this.mins = parseInt(value, 10);
        value -= parseInt(value, 10);
        value *= 60;
        this.secs = parseInt(value, 10);
        this.displayHours = this.hours;
        if (this.hours === 0) {
            this.displayHours = '00';
        } else if (this.hours > 0) {
            this.displayHours = (this.hours < 10) ? '+0' + this.hours : '+' + this.hours;
        } else {
            this.displayHours = (this.hours > -10) ? '-0' + Math.abs(this.hours) : this.hours;
        }

        this.mins = (this.mins < 10) ? '0' + this.mins : this.mins;
        return this.displayHours + ':' + this.mins;
    }


}
