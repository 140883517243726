
import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-formate';


@NgModule({
    declarations: [
        DateFormatPipe
    ],

    exports: [DateFormatPipe]
})
export class DatepipeModule {
}

