import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CookieService } from 'ngx-cookie-service';
import { AppCommonService } from '../content/apps/app.service';
import { NavbarService } from '../navbar/navbar.service';
import { currentId } from 'async_hooks';
import { id } from '@swimlane/ngx-charts/release/utils';
import { UnsubscribeOnDestroyAdapter } from 'app/unsubscribe-on-destroy-adapter';
import { SubSink } from '../../sub-sink'
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CommonModalComponent } from '@fuse/components/common-modal/common-modal.component';
import { ServerDateTimeService } from '@fuse/services/server-datetime.service';
@Component({
    selector: 'fuse-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class FuseToolbarComponent implements OnInit, OnDestroy {
    private subs = new SubSink();
    userStatusOptions: any[];
    languages: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    alertList: any = {};
    noNav: boolean;
    navigation: any;
    currentMenu: any;
    adminCompanyMenuShow: boolean;
    user: any = {};
    userDetails: any = {};
    allNavigation: any;
    dialogRef: any;
    activeClass: any;
    isUser = false;
    projectUrl = '';
    navLinks = [
        { name: 'Home', Id: 'home', RoleId: [], url: '/apps/dashboards' },
        // { name: 'Assignment', Id: 'Assignments', RoleId: [], url: '/apps/myassignment/my-assignment' },
        { name: 'Activities', Id: 'Activities', RoleId: [], url: '/apps/myassignment/assignments' },
        { name: 'Prospects', Id: 'prospects', RoleId: [], url: '/apps/lead/list' },
        { name: 'Clients', Id: 'clients', RoleId: [], url: '/apps/clients/list' },
        { name: 'Projects', Id: 'project', RoleId: [], url: '/apps/projects/list' },
        { name: 'Services', Id: 'services', RoleId: [], url: '/apps/service/list' },
        { name: 'Billing', Id: 'Billing', RoleId: [], url: '/apps/invoices/due-for-invoicing' },
        { name: 'Reports', Id: 'reports', RoleId: [], url: '/apps/reports/login-history' },
        { name: 'campaign', Id: 'campaign', RoleId: [], url: '/apps/campaign/raw-data' },
        // { name: 'Configuration', Id: 'configure', RoleId: [], url: '/apps/admin/master/industry-type' },
        { name: 'Set Up Company', Id: 'company', RoleId: [], url: '/apps/admin/company/set-up-company' },
        { name: 'Management', Id: 'management', RoleId: [], url: '/apps/management/management-projects' },
        { name: 'Request', Id: 'Request', RoleId: [], url: '/apps/leave-application/my-request' },
    ];
    submenuList = [];
    usercurrentroles: string;
    currentRole: any;
    userRoles: FormGroup;
    InputAlert: any = {
        columns: [{ data: "CreatedDate", name: "", searchable: true, orderable: true }],
        draw: 1,
        length: 10,
        order: [{ column: 0, dir: "desc" }],
        search: { value: "", regex: false },
        start: 0,
        IsView: true
    }
    LoginId: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    logOutData: any = {};
    constructor(
        private router: Router,
        private fuseConfig: FuseConfigService,
        private sidebarService: FuseSidebarService,
        private translate: TranslateService,
        private cookieService: CookieService,
        private appService: AppCommonService,
        private navbarService: NavbarService,
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private serverDateTime: ServerDateTimeService,
        public dialog: MatDialog,
    ) {
        // super();
        if (localStorage.getItem('token')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.LoginId = JSON.parse(localStorage.getItem('LoginId'));

            console.log(this.user)
            this.isUser = true;
            this.subs.sink = this.appService.postService(null, 'MenuSectionGetById')
                .subscribe(response => {
                    // this.submenuList = this.navLinks.splice(7, 5);
                    // 
                    this.allNavigation = response.body.Result.navigation;
                    this.navLinks.forEach(data => {
                        const roleId = this.allNavigation.filter(x => x.Mid === data.Id)[0];
                        if (roleId.Mid) {
                            data.RoleId = roleId.RoleId;
                        }
                    });
                    this.submenuList = this.navLinks.splice(7, 6);
                });
        }

        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                'id': 'en',
                'title': 'English',
                'flag': 'us'
            },
            {
                'id': 'tr',
                'title': 'Turkish',
                'flag': 'tr'
            }
        ];

        this.selectedLanguage = this.languages[0];

        this.subs.sink = router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.showLoadingBar = true;
                }
                if (event instanceof NavigationEnd) {
                    this.showLoadingBar = false;
                }
            });

        this.subs.sink = this.fuseConfig.onConfigChanged.subscribe((settings) => {
            this.horizontalNav = settings.layout.navigation === 'top';
            this.noNav = settings.layout.navigation === 'none';
        });

    }


    ngOnInit() {
        this.getUserDetails();
        this.subs.sink = this.navbarService.change.subscribe(menu => {
            this.activeClass = menu;
        });
        this.activeClass = localStorage.getItem('isOpen');
        if (localStorage.getItem('token')) {
            this.alertsList();
        }
        let usercurrentroles = JSON.parse(localStorage.getItem('user'));
        if (usercurrentroles) {
            this.usercurrentroles = usercurrentroles.UserRoleList || usercurrentroles.UserRole.UserRoleList;
            if (usercurrentroles.UserRole.UserRoleList) {
                this.usercurrentroles = JSON.parse(this.usercurrentroles)
                this.currentRole = usercurrentroles.UserRole.UserRoleName;
            }
            else if (usercurrentroles.UserRoleList) {
                for (let e = 0; e < usercurrentroles.UserRoleList.length; e++) {
                    if (usercurrentroles.UserRoleList[e].UserRoleId == usercurrentroles.UserRole.UserRoleId) {
                        this.currentRole = usercurrentroles.UserRoleList[e].UserRoleName;
                    }
                }
            }
            this.userRoles = this.fb.group({
                currentRolename: [null, Validators.required]
            });

            // const toSelect = this.patientCategories.find(c => c.id == 3);
            this.userRoles.get('currentRolename').setValue(this.currentRole);
        }

    }


    alertsList = () => {
        this.InputAlert.AssignedTo = { EmployeeId: this.user.Id }
        this.appService.postService({ ...this.InputAlert }, 'DashboardAlertsList')
            .subscribe(response => {
                this.alertList = response.body.Result;
            });
    }
    navMenu(id) {
        this.navbarService.menuOpen(id);
        this.activeClass = id;
        localStorage.setItem('isOpen', id);
    }
    getUserDetails = () => {
        if (this.user.Id) {
            this.subs.sink = this.appService.postService({ Id: this.user.Id }, 'EmployeeGetById')
                .subscribe(response => {
                    this.userDetails = response.body.Result;
                    console.log(this.userDetails)
                });
        }
    }
    toggleSidebarOpened(key) {
        this.sidebarService.getSidebar(key).toggleOpen();
    }


    setLanguage(lang) {
        // Set the selected language for toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translate.use(lang.id);
    }
    changePassword() {
        this.dialogRef = this.dialog.open(ForgetPasswordComponent, {
            panelClass: 'modal-sm',
            autoFocus: false,
            disableClose: true,
            data: { action: 'ChangePassword', modalTitle: 'Change Password' }
        });
        // this.dialogRef.afterClosed()
        //     .subscribe(result => { 

        //     })
    }

    logout() {
        this.spinner.show();
        this.logOutData.IPAddress = this.appService.IPAddress;
        this.logOutData.TimeZone = this.serverDateTime.serverDateTime();
        this.logOutData.LoginId = this.LoginId;
        this.logOutData.IsActive = true;
        this.appService.login(this.logOutData, 'login/Logout')
            .subscribe(response => {
                if (response.body.IsUserAccess && response.body.IsUserActive) {
                    if (response.ok && response.status === 200) {
                        localStorage.clear();
                        this.cookieService.delete('emailId');
                        this.router.navigate(['apps/login']);
                        this.spinner.hide();
                    }
                } else if (!response.body.IsUserAccess && response.body.IsUserActive) {
                    this.dialogRef = this.dialog.open(CommonModalComponent, {
                        panelClass: 'modal-sm',
                        data: { action: 'LogoutReason' },
                        autoFocus: false,
                    });
                    this.dialogRef.afterClosed()
                        .subscribe(result => {
                            if (!result) {
                                return;
                            }
                            else {
                                console.log(result)
                                this.logOutData.Reason = result.Reason;
                                this.logout();
                            }
                        });
                    this.spinner.hide();
                }
            })
    }

    chnageUserRole(event, usercurrentroles) {
        this.spinner.show();
        let usercurrentRoles = JSON.parse(localStorage.getItem('user'));
        const CloneData = { ...usercurrentRoles }
        for (let u = 0; u < usercurrentroles.length; u++) {
            if (usercurrentroles[u].UserRoleName == event.value) {
                usercurrentroles[u].UserRoleList = JSON.stringify(usercurrentroles)
                let UserRole = {
                    "UserRole": usercurrentroles[u],
                    "Id": null,
                    "ExpirationTime": '',
                    "LoginDate": '',
                }
                UserRole.Id = usercurrentRoles.Id;
                UserRole.ExpirationTime = CloneData.ExpirationTime;
                UserRole.LoginDate = CloneData.LoginDate;

                localStorage.setItem('user', JSON.stringify(UserRole));
                this.router.navigate(['/apps/dashboards']).then(() => {
                    window.location.reload();
                })
                this.spinner.hide();
            }
        }
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
