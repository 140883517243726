import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationTurkish } from './navigation/i18n/tr';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { AppCommonService } from './main/content/apps/app.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material';
import { ForgetPasswordComponent } from './main/forget-password/forget-password.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerDateTimeService } from '@fuse/services/server-datetime.service';
const moment = _moment;
@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  timeLeft: number;
  interval;
  LoginId: any;
  dialogRef: any;
  timeOut = false;
  user: any;
  logOutData: any = {};
  subscription: Subscription;
  logoutHrs: any
  IsChanged: any;
  constructor(
    private translate: TranslateService,
    private router: Router, private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private serverDateTime: ServerDateTimeService,
    private appService: AppCommonService,
    private cookieService: CookieService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSplashScreen: FuseSplashScreenService,
    private fuseTranslationLoader: FuseTranslationLoaderService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.LoginId = JSON.parse(localStorage.getItem('LoginId'));

    if (this.user) {
      var duration = moment(this.user.ExpirationTime).diff(moment(this.user.LoginDate));
      console.log(duration);
      //60 == 1min && 900000 == 15min
      setInterval(() => this.startCountdown(60), 900000);
      setTimeout(() => this.logout(), duration); 
    }
    // Add languages
    this.translate.addLangs(['en', 'tr']);

    // Set the default language
    this.translate.setDefaultLang('en');

    // Set the navigation translations
    this.fuseTranslationLoader.loadTranslations(navigationEnglish, navigationTurkish);

    // Use a language
    this.translate.use('en');
    this.IsChanged = localStorage.getItem('needpassword');
    if (this.IsChanged == 'change') {
      this.changePassword();
  }
  }
  changePassword() {
    this.dialogRef = this.dialog.open(ForgetPasswordComponent, {
        panelClass: 'modal-sm',
        autoFocus: false,
        disableClose: true,
        data: { action: 'ChangePassword', modalTitle: 'Change Password' }
    });
    // this.dialogRef.afterClosed()
    //     .subscribe(result => { 

    //     })
}
  logout() {
    this.spinner.show();
    this.logOutData.IPAddress = this.appService.IPAddress;
    this.logOutData.TimeZone = this.serverDateTime.serverDateTime();
    this.logOutData.LoginId = this.LoginId;
    this.logOutData.IsActive = false;
    this.appService.login(this.logOutData, 'login/Logout')
        .subscribe(response => {
          localStorage.clear();
          this.cookieService.delete('emailId');
          this.router.navigate(['apps/login']);
          this.spinner.hide();
        })
  }

  reloadPage(a) {
    if (a == 1) {
      window.location.reload();
    } else {
      this.timeLeft = 0;
    }
  }

  startCountdown(seconds) {
    this.timeLeft = seconds;
    const interval = setInterval(() => {
      this.timeLeft--;
      if (this.timeLeft <= 0) {
        console.log(this.timeLeft)
        if (this.timeLeft > -1) {
          this.reloadPage(1);
        }
        clearInterval(interval);
      }
    }, 1000);
  }
}
