import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatIconModule } from '@angular/material';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';

console.log('test')
@NgModule({
    declarations: [
        FuseConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        MatIconModule
    ],
    entryComponents: [
        FuseConfirmDialogComponent
    ],
    providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }]
})
export class FuseConfirmDialogModule
{
}
