import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  constructor() { }
  menuOpen(a) {
    this.change.emit(a);
  }
}
