import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule, MatDialogModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseToolbarComponent } from 'app/main/toolbar/toolbar.component';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { AppCommonService } from '../content/apps/app.service';
import { HttpErrorHandler } from '../content/http-error-handler.service';
import { MessageService } from '../content/message.service';
import { CommonModalComponent } from '@fuse/components/common-modal/common-modal.component';
import { CommonModalModule } from '@fuse/components/common-modal/common-modal.module';
import { ServerDateTimeService } from '@fuse/services/server-datetime.service';

@NgModule({
    declarations: [
        FuseToolbarComponent,        
    ],
    imports: [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatDialogModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,   
        CommonModalModule,
        
    ],
    exports: [
        FuseToolbarComponent
    ],
    providers: [AppCommonService, HttpErrorHandler, MessageService, ServerDateTimeService],
    entryComponents: [CommonModalComponent]
})
export class FuseToolbarModule {
}
