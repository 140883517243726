import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatDialogModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseNavbarComponent } from 'app/main/navbar/navbar.component';
import { FuseNavigationModule, FuseConfirmDialogModule } from '@fuse/components';
import { AppCommonService } from '../content/apps/app.service';
import { HttpErrorHandler } from '../content/http-error-handler.service';
import { MessageService } from '../content/message.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { NavbarService } from './navbar.service';

@NgModule({
    declarations: [
        FuseNavbarComponent
    ],
    imports: [
        RouterModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,

        FuseSharedModule,
        FuseNavigationModule,
        FuseConfirmDialogModule
    ],
    exports: [
        FuseNavbarComponent
    ],
    providers: [AppCommonService, HttpErrorHandler, MessageService, NavbarService],
    entryComponents: [FuseConfirmDialogComponent]
})
export class FuseNavbarModule {
}
