import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from '@fuse/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { NumberDirective } from './number-only/numbers-only.directive';
import { SpaceDirective } from './first-space/space.directive';
    import { from } from 'rxjs';
//import { AccessibleFormDirective } from './scroll_to/scroll.directive';

@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        NumberDirective,
        SpaceDirective
        //AccessibleFormDirective
    ],
    imports     : [],
    exports     : [
        FuseIfOnDomDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        NumberDirective,
        SpaceDirective
        //AccessibleFormDirective
    ]
})
export class FuseDirectivesModule
{
}
